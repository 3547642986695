import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://greens-hugk.onrender.com/',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        'accept': '*/*',
        'Authorization': `JWT ${localStorage.getItem("green_token")}`
    }
});

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        return Promise.reject(error);
    }
);
export default axiosInstance;

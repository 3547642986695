import { useEffect, useState } from "react"
import Base from "../layout/LandingPage"
import axiosInstance from "../axiosInstance"
import { Link } from "react-router-dom"
import img1 from "../gallery/1.jpg";
import img2 from "../gallery/2.jpg";
import img3 from "../gallery/3.jpg";
import img4 from "../gallery/4.jpg";
import img5 from "../gallery/5.jpg";
import img6 from "../gallery/6.jpg";
import img7 from "../gallery/7.jpg";
import img8 from "../gallery/8.jpg";
import img9 from "../gallery/9.jpg";
import img10 from "../gallery/10.jpg";
import img11 from "../gallery/11.jpg";
import img12 from "../gallery/12.jpg";
import img13 from "../gallery/13.jpg";
import img14 from "../gallery/14.jpg";
import img15 from "../gallery/15.jpg";
import img16 from "../gallery/16.jpg";
import img17 from "../gallery/17.jpg";
import img18 from "../gallery/18.jpg";
import img19 from "../gallery/19.jpg";
import img20 from "../gallery/20.jpg";
import img21 from "../gallery/21.jpg";
import img22 from "../gallery/22.jpg";
import img23 from "../gallery/23.jpg";
import img24 from "../gallery/24.jpg";
import img25 from "../gallery/25.jpg";
import img26 from "../gallery/26.jpg";
import img27 from "../gallery/27.jpg";
import img28 from "../gallery/28.jpg";
import img29 from "../gallery/29.jpg";
import img30 from "../gallery/30.jpg";
import img31 from "../gallery/31.jpg";
import img32 from "../gallery/32.jpg";
import img33 from "../gallery/33.jpg";
import img34 from "../gallery/34.jpg";
import img35 from "../gallery/35.jpg";
import img36 from "../gallery/36.jpg";

const Gallery = () => {

    const [data, setData] = useState("")

    useEffect(() => {
        // getData(1)
        //eslint-disable-next-line
    }, [])

    const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24, img25, img26, img27, img28, img29, img30, img31, img32, img33, img34, img35, img36]

    // const getData = (activePage) => {
    //     axiosInstance(`api/gallery/?page=${activePage}`, {
    //         baseURL: "https://greens-hugk.onrender.com/",
    //         method: "GET",
    //     }).then(res => {
    //         setData({ ...res?.data, page: Math.round(res?.data?.count / 12) === 0 ? 1 : Math.round(res?.data?.count / 12) })
    //     }).catch(err => {
    //         getData(1)
    //     })
    // }

    return (
        <Base>
            <div className="container">
                <h1 className="title">Gallery</h1>
                <div className="row gallery">
                    {
                        images?.map((res, id) => {
                            return (
                                <div className="col-12 col-md-4 wowload fadeInUp" key={id}>
                                    <a
                                        href="#"
                                        className="gallery-image"
                                        data-gallery=""
                                    >
                                        <img
                                            src={res}
                                            style={{ maxHeight: "24rem", minWidth: "32rem", maxWidth: "32rem", minHeight: "24rem" }}
                                            className="img-responsive"
                                        />
                                    </a>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="text-center">
                    {/* <ul className="pagination">
                        <li onClick={() => getData(1)}>
                            <Link href="#">«</Link>
                        </li>
                        {
                            new Array(data?.page)?.fill("")?.map((res, id) => {
                                return (
                                    <li key={id} onClick={() => getData(id + 1)}>
                                        <Link to="#">{id + 1}</Link>
                                    </li>
                                )
                            })
                        }
                        <li onClick={() => getData(data?.page)}>
                            <Link href="#">»</Link>
                        </li>
                    </ul> */}
                </div>
            </div>

        </Base>
    )
}

export default Gallery
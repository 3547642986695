import { useEffect, useState } from "react"
import Base from "../layout/LandingPage"
import axiosInstance from "../axiosInstance"
import { Link } from "react-router-dom"
import img1 from "../rooms/1.jpg";
import img2 from "../rooms/2.jpg";
import img3 from "../rooms/3.jpg";
import img4 from "../rooms/4.jpg";
import img5 from "../rooms/5.jpg";
import img6 from "../rooms/6.jpg";

const RoomsTariff = () => {

    const [data, setData] = useState("")

    useEffect(() => {
        // getData(1)
        //eslint-disable-next-line
    }, [])

    // const getData = (number) => {
    //     axiosInstance(`api/rooms/?page=${number}`, {
    //         baseURL: "https://greens-hugk.onrender.com/",
    //         method: "GET",
    //     }).then(res => {
    //         setData({ ...res?.data, page: Math.round(res?.data?.count / 12) === 0 ? 1 : Math.round(res?.data?.count / 12) })
    //     }).catch(err => {
    //         getData(1)
    //     })
    // }

    const rooms = [
        { image: img2, room_type: "Delux Room" },
        { image: img4, room_type: "Couple Room" },
        { image: img3, room_type: "Family Room" },
        { image: img1, room_type: "Party Hall" },
    ]

    const hut = [
        { image: img5, room_type: "Hut House - H1" },
        { image: img6, room_type: "Hut House - H2" },
    ]

    return (
        <Base>
            <div className="container">
                <h2>Rooms</h2>
                {/* form */}
                <div className="row">
                    {
                        rooms?.map((res, id) => {
                            return (
                                <div className="col-12 col-sm-6 wowload fadeInUp" key={id}>
                                    <div className="rooms">
                                        <img
                                            src={res?.image}
                                            style={{ width: "100%", maxWidth: "55.5rem", minHeight: "41.7rem", maxHeight: "41.7rem" }}
                                            className="img-responsive"
                                        />
                                        <div className="info">
                                            <h3>{res?.room_type}</h3>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="text-center">
                    {/* <ul className="pagination">
                        <li onClick={() => getData(1)}>
                            <Link to="#">«</Link>
                        </li>
                        {
                            new Array(data?.page)?.fill("")?.map((res, id) => {
                                return (
                                    <li key={id} onClick={() => getData(id + 1)}>
                                        <Link to="#">{id + 1}</Link>
                                    </li>
                                )
                            })
                        }
                        <li onClick={() => getData(data?.page)}>
                            <Link href="#">»</Link>
                        </li>
                    </ul> */}
                </div>
            </div>
            <div className="container">
                <h2>Hut House</h2>
                {/* form */}
                <div className="row">
                    {
                        hut?.map((res, id) => {
                            return (
                                <div className="col-12 col-sm-6 wowload fadeInUp" key={id}>
                                    <div className="rooms">
                                        <img
                                            src={res?.image}
                                            style={{ width: "100%", maxWidth: "55.5rem", minHeight: "41.7rem", maxHeight: "41.7rem" }}
                                            className="img-responsive"
                                        />
                                        <div className="info">
                                            <h3>{res?.room_type}</h3>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="text-center">
                    {/* <ul className="pagination">
                        <li onClick={() => getData(1)}>
                            <Link to="#">«</Link>
                        </li>
                        {
                            new Array(data?.page)?.fill("")?.map((res, id) => {
                                return (
                                    <li key={id} onClick={() => getData(id + 1)}>
                                        <Link to="#">{id + 1}</Link>
                                    </li>
                                )
                            })
                        }
                        <li onClick={() => getData(data?.page)}>
                            <Link href="#">»</Link>
                        </li>
                    </ul> */}
                </div>
            </div>
        </Base>
    )
}

export default RoomsTariff
import { useState } from "react"
import Base from "../layout/LandingPage"
import ReactStars from 'react-stars'
import axiosInstance from "../axiosInstance"
import Swal from "sweetalert2";

const Feedback = () => {

    const [input, setInput] = useState({
        cust_name: "",
        cust_image: "",
        rating: "",
        feedback: ""
    })

    const [errors, setErrors] = useState({
        cust_name: "",
        rating: "",
        feedback: ""
    })

    const handleChange = (e, name) => {
        if (e?.target?.name === "cust_image") {
            setInput({ ...input, [e.target.name]: e.target?.files[0] })
        } else if (name === "rating") {
            setInput({ ...input, rating: +e })
        } else {
            setInput({ ...input, [e.target.name]: e.target?.value })
        }
    }
    const handleSubmit = async () => {

        if (validate()) {
            Swal.fire({
                title: "Thank you!",
                text: "Thank you for your valuable feedback!",
                icon: "success"
            });
            setInput({
                cust_name: "",
                cust_image: "",
                rating: "",
                feedback: ""
            })
            // axiosInstance(`api/feedback/`, {
            //     baseURL: "https://greens-hugk.onrender.com/",
            //     method: "POST",
            //     data: { ...input, cust_image: null }
            // }).then(res => {

            // }).catch(err => console.log(err))
        }
    }
    const validate = (fieldValues = input) => {
        let temp = { ...errors };

        if ("cust_name" in fieldValues) {
            temp.cust_name = fieldValues.cust_name?.trim() === "" ? "Please Enter Name" : "";
        }
        if ("rating" in fieldValues) {
            temp.rating = fieldValues.rating === "" ? "Please Give Rating" : "";
        }
        if ("feedback" in fieldValues) {
            temp.feedback = fieldValues.feedback?.trim() === "" ? "Please Enter Feedback" : "";
        }
        setErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === "");
    };

    return (
        <Base>
            <div className="container">
                <h1 className="title">Your Feedback adds value to us</h1>
                {/* form */}
                <div className="contact" style={{ marginBottom: "20px" }}>
                    <div className="row">
                        <div className="col-sm-6 col-sm-offset-3">
                            <div>
                                <h4>Write to us</h4>
                                <div className="form-group">
                                    <input
                                        name="cust_name"
                                        value={input?.cust_name}
                                        onChange={handleChange}
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        placeholder="Name"
                                    />
                                    <span className="text-danger">{errors?.cust_name}</span>
                                </div>
                                {/* <div className="form-group">
                                    <label>Select your image</label>
                                    <input
                                        name="cust_image"
                                        accept=".jpg,.png,.jpeg"
                                        onChange={handleChange}
                                        type="file"
                                        className="form-control"
                                        id="email"
                                    />
                                </div> */}
                                <div className="form-group">
                                    <label>Rate Us</label>
                                    <ReactStars
                                        count={5}
                                        onChange={(e) => handleChange(e, "rating")}
                                        size={24}
                                        color2={'#ffd700'}
                                        half={false}
                                        value={input?.rating}
                                    />
                                    <span className="text-danger">{errors?.rating}</span>
                                </div>
                                <div className="form-group">
                                    <textarea
                                        name="feedback"
                                        value={input?.feedback}
                                        onChange={handleChange}
                                        type="text"
                                        className="form-control"
                                        placeholder="Message"
                                        rows={4}
                                        defaultValue={""}
                                    />
                                    <span className="text-danger">{errors?.feedback}</span>
                                </div>
                                <button onClick={handleSubmit} className="btn btn-default">
                                    Send
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Base>
    )

}
export default Feedback
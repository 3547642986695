import { useEffect, useState } from "react"
import "./style.css"
import axiosInstance from "../axiosInstance"
import DefaultUser from "../static/photos/user.jpg"

const Testimonials = () => {

    const [activeIndex, setActiveIndex] = useState(1)

    useEffect(() => {
        // getData()
        //eslint-disable-next-line
    }, [])

    // const getData = () => {
    //     axiosInstance(`api/feedback/`, {
    //         baseURL: "https://greens-hugk.onrender.com/",
    //         method: "GET",
    //     }).then(res => {
    //         setData(res?.data?.results)
    //     }).catch(err => {
    //         getData()
    //     })
    // }

    const data = [
        { cust_name: "Rahul Sharma", feedback: "Had a wonderful experience at SJR Residency! The rooms were clean, the staff was friendly, and the view from the hotel was breathtaking. Highly recommend for a relaxing getaway!", rating: "4" },
        { cust_name: "Priya R", feedback: "The staff was very welcoming, and the rooms were spotless. The food was delicious, and the location on Yelagiri Hills made the stay even more special. Highly recommend!", rating: "5" },
        { cust_name: "Varshini", feedback: "Stayed here for a weekend and absolutely loved it! The serene atmosphere, cozy rooms, and great hospitality made it a perfect retreat. Will visit again!", rating: "5" },
        { cust_name: "Ananya ", feedback: "Had a great time with my family here. The staff was friendly, and the food was amazing. The kids enjoyed the play area, making it a memorable stay for all of us!", rating: "4" },
        { cust_name: "Rohit", feedback: "The hotel was well-maintained, the rooms were spacious, and the service was top-notch. A great place to relax and unwind in nature.", rating: "3.5" }
    ]

    return (
        <>
            <section>
                <div className="customer-feedback">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-sm-offset-2 col-sm-8">
                                <div>
                                    <h2 className="section-title">What Clients Say</h2>
                                </div>
                            </div>
                            {/* /End col */}
                        </div>
                        {/* /End row */}
                        <div className="row">
                            <div className="col-md-offset-3 col-md-6 col-sm-offset-2 col-sm-8">
                                <div className="owl-carousel feedback-slider">
                                    <div className="feedback-slider-item">
                                        <img
                                            src={data?.[activeIndex]?.cust_image || DefaultUser}
                                            width={"100px"}
                                            className="center-block img-circle"
                                            alt="Customer Feedback"
                                        />
                                        <h3 className="customer-name">{data?.[activeIndex]?.cust_name}</h3>
                                        <p>
                                            {data?.[activeIndex]?.feedback}
                                        </p>
                                        <span className="light-bg customer-rating" data-rating={5} onClick={() => data?.length - 1 !== activeIndex && setActiveIndex(activeIndex + 1)}>
                                            {data?.[activeIndex]?.rating}
                                            <i className="fa fa-star" />
                                        </span>
                                    </div>
                                </div>
                                {/* /End feedback-slider */}
                                {/* side thumbnail */}
                                <div className="feedback-slider-thumb hidden-xs">
                                    <div className="thumb-prev" onClick={() => activeIndex > 0 && setActiveIndex(activeIndex - 1)}>
                                        <span>
                                            <img
                                                src={data?.[activeIndex - 1]?.cust_image || DefaultUser}
                                                alt="Customer Feedback"
                                            />
                                        </span>
                                        <span className="light-bg customer-rating">
                                            {data?.[activeIndex - 1]?.rating}
                                            <i className="fa fa-star" />
                                        </span>
                                    </div>
                                    <div className="thumb-next" onClick={() => data?.length - 1 !== activeIndex && setActiveIndex(activeIndex + 1)}>
                                        <span>
                                            <img
                                                src={data?.[activeIndex + 1]?.cust_image || DefaultUser}
                                                alt="Customer Feedback"
                                            />
                                        </span>
                                        <span className="light-bg customer-rating">
                                            {data?.[activeIndex + 1]?.rating}
                                            <i className="fa fa-star" />
                                        </span>
                                    </div>
                                </div>
                                {/* /side thumbnail */}
                            </div>
                            {/* /End col */}
                        </div>
                        {/* /End row */}
                    </div>
                    {/* /End container */}
                </div>
                {/* /End customer-feedback */}
            </section >
        </>
    )
}

export default Testimonials
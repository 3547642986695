import { useEffect, useState } from "react"
import Base from "../layout/LandingPage"
import axiosInstance from "../axiosInstance"

const Amenities = () => {

    const [data, setData] = useState("")

    useEffect(() => {
        // getData()
        //eslint-disable-next-line
    }, [])

    const activity = [
        { name: "Free Wi-Fi", description: "Stay connected with high-speed internet access throughout the hotel." },
        { name: "Room Service ", description: "Order food and beverages delivered to your room anytime." },
        { name: "Air Conditioning", description: "Relax in a climate-controlled, comfortable environment." },
        { name: "24/7 Front Desk", description: "Our team is available round the clock for assistance." },
        { name: "Free Parking", description: "Hassle-free parking space for all guests." },
        { name: "Housekeeping Service", description: "Enjoy a clean and tidy room every day." },
        { name: "Conference & Banquet Facilities", description: "Host meetings, events, or celebrations with ease." },
        { name: "Banquet Hall", description: "A spacious, well-equipped venue for weddings, corporate events, and celebrations, with modern amenities and catering services." },
    ]

    // const getData = () => {
    //     axiosInstance(`api/amenity/`, {
    //         baseURL: "https://greens-hugk.onrender.com/",
    //         method: "GET",
    //     }).then(res => {
    //         setData(res?.data)
    //     }).catch(err => {
    //         getData()
    //         console.log('err: ', err);
    //     })
    // }
    return (
        <>
            <Base>
                <div className="container">
                    <h1 className="title">Amenities</h1>
                    <div>
                        <ol>
                            {
                                activity?.map(res => {
                                    return (
                                        <li style={{ padding: "10px" }}>
                                            <h3>{res?.name}</h3>
                                            <h4>{res?.description}</h4>
                                        </li>
                                    )
                                })
                            }
                        </ol>
                    </div>
                </div>
            </Base>
        </>
    )
}

export default Amenities
import { useParams } from "react-router-dom"
import Base from "../layout/LandingPage"
import { useEffect, useState } from "react"
import axiosInstance from "../axiosInstance"


const RoomDetailPage = () => {

    const { id } = useParams()
    const [data, setData] = useState({})

    // useEffect(() => {
    //     axiosInstance(`api/rooms/${id}`, {
    //         baseURL: "https://greens-hugk.onrender.com/",
    //         method: "GET",
    //     }).then(res => {
    //         setData(res?.data)
    //     }).catch(err => {
    //         console.log('err: ', err);
    //     })
    //     // eslint-disable-next-line
    // }, [])

    return (
        <Base>
            <div className="container">
                <h1 className="title">{data?.room_type}</h1>

                {/* RoomCarousel*/}
                <div className="room-features">
                    <div className="row">
                        <div className="col-sm-12 col-md-5">
                            <p>
                                {data?.description}{" "}
                            </p>
                        </div>
                        <div className="col-sm-6 col-md-3 amenitites">
                            <h3>Amenitites</h3>

                            {
                                data?.amenities?.map(item => {
                                    return (
                                        <ul>
                                            <li>{item}</li>
                                        </ul>
                                    )
                                })
                            }
                        </div>
                        <div className="col-sm-3 col-md-2">
                            <div className="size-price">
                                Size<span>44 sq</span>
                            </div>
                        </div>
                        <div className="col-sm-3 col-md-2">
                            <div className="size-price">
                                Price per night<span> ₹ {data?.price_per_night}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Base >
    )
}

export default RoomDetailPage
import { useEffect, useState } from "react"
import Base from "../layout/LandingPage"
import boat from "../static/visits/boat.jpg"
import nature from "../static/visits/nature.jpg"
import swami from "../static/visits/swami.jpg"
import view from "../static/visits/view.jpeg"
import water from "../static/visits/water.jpg"
import advent from "../static/visits/advent.jpeg"
import axiosInstance from "../axiosInstance"
import { Link } from "react-router-dom"
import img1 from "../activities/1.jpg";
import img2 from "../activities/2.jpg";
import img3 from "../activities/3.jpg";
import img4 from "../activities/4.jpg";
import img5 from "../activities/5.jpg";
import img6 from "../activities/6.jpg";

const Activities = () => {

    const [activeTab, setActiveTab] = useState("activity")
    const [data, setData] = useState("")

    const images = [img1, img2, img3, img4, img5, img6];

    useEffect(() => {
        // getData(1)
        //eslint-disable-next-line
    }, [])

    // const getData = (activePage) => {
    //     axiosInstance(`api/activity/?page=${activePage}`, {
    //         baseURL: "https://greens-hugk.onrender.com/",
    //         method: "GET",
    //     }).then(res => {
    //         setData({ ...res?.data, page: Math.round(res?.data?.count / 12) === 0 ? 1 : Math.round(res?.data?.count / 12) })
    //     }).catch(err => {
    //         getData(1)
    //         console.log('err: ', err);
    //     })
    // }

    return (
        <Base>
            <div className="container">
                <ul className="nav nav-tabs" style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <li style={{ marginRight: "20px" }} onClick={() => setActiveTab("activity")} className={activeTab === "activity" ? "nav-item activity_tab_style" : "activity_tab_style_inactive"}>
                        <h4 className="nav-link active" aria-current="page" href="#">Our Activities</h4>
                    </li>
                    <li onClick={() => setActiveTab("visiting")} className={activeTab === "visiting" ? "nav-item activity_tab_style" : "activity_tab_style_inactive"}>
                        <h4 className="nav-link" href="#">Visiting Places</h4>
                    </li>
                </ul>

                {activeTab === "activity" ?
                    <div>
                        <div className="row gallery">
                            {
                                images?.map((res, id) => {
                                    return (
                                        <div className="col-12 col-md-4" key={id}>
                                            <a
                                                href="#"
                                                className="gallery-image"
                                                data-gallery=""
                                            >
                                                <img
                                                    src={res}
                                                    style={{ width: "100%", maxHeight: "24rem", minWidth: "36rem", minHeight: "24rem" }}
                                                    className="img-responsive"
                                                />
                                            </a>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    : <div>
                        <div className="row gallery">
                            <div className="col-sm-4 wowload fadeInUp">
                                <h3> Boad house</h3>
                                <a
                                    href="#"
                                    title="Foods"
                                    className="gallery-image"
                                    data-gallery=""
                                >
                                    <img src={boat} className="img-responsive" />
                                </a>
                            </div>
                            <div className="col-sm-4 wowload fadeInUp">
                                <h3>Nature Park</h3>
                                <a
                                    href="#"
                                    title="Coffee"
                                    className="gallery-image"
                                    data-gallery=""
                                >
                                    <img src={nature} style={{ width: "36rem", maxHeight: "24rem" }} className="img-responsive" />
                                </a>
                            </div>
                            <div className="col-sm-4 wowload fadeInUp">
                                <h3>Swami Malai (Trekking Spot)</h3>
                                <a
                                    href="#"
                                    title="Travel"
                                    className="gallery-image"
                                    data-gallery=""
                                >
                                    <img src={swami} className="img-responsive" />
                                </a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 wowload fadeInUp">
                                <h3>View Point</h3>
                                <a
                                    href="#"
                                    title="Adventure"
                                    className="gallery-image"
                                    data-gallery=""
                                >
                                    <img src={view} className="img-responsive" />
                                </a>
                            </div>
                            <div className="col-sm-4 wowload fadeInUp">
                                <h3>Water Falls</h3>
                                <a
                                    href="#"
                                    title="Adventure"
                                    className="gallery-image"
                                    data-gallery=""
                                >
                                    <img src={water} style={{ width: "36rem", height: "18rem" }} className="img-responsive" />
                                </a>
                            </div>
                            <div className="col-sm-4 wowload fadeInUp">
                                <h3>Other Adventure Activities</h3>
                                <a
                                    href="#"
                                    className="gallery-image"
                                    data-gallery=""
                                >
                                    <img src={advent} className="img-responsive" />
                                </a>
                            </div>
                        </div>
                    </div>

                }
                {
                    activeTab === "activity" && data !== "" &&
                    <div className="text-center">
                        {/* <ul className="pagination">
                            <li onClick={() => getData(1)}>
                                <Link to="#">«</Link>
                            </li>
                            {
                                new Array(data?.page)?.fill("")?.map((res, id) => {
                                    return (
                                        <li key={id} onClick={() => getData(id + 1)}>
                                            <Link to="#">{id + 1}</Link>
                                        </li>
                                    )
                                })
                            }
                            <li onClick={() => getData(data?.page)}>
                                <Link to="#">»</Link>
                            </li>
                        </ul> */}
                    </div>
                }
            </div>
        </Base >
    )
}

export default Activities
import { HashRouter, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import RoomsTariff from "./pages/RoomsTariff";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import Activities from "./pages/Activities";
import About from "./pages/About";
import RoomDetailPage from "./pages/RoomDetailPage";
import Feedback from "./pages/Feedback";
import Amenities from "./pages/Amenities";

function App() {

  return (
    <>
      <HashRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/roomstariff' element={<RoomsTariff />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/activities' element={<Activities />} />
          <Route path='/about' element={<About />} />
          <Route path='/roomdetails/:id' element={<RoomDetailPage />} />
          <Route path='/feedback' element={<Feedback />} />
          <Route path='/amenities' element={<Amenities />} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
